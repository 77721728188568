.esprit {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #a7d1e7;

  h2 {
    font-size: 36px;
    margin-top: 34px;
    color: white;
    font-weight: 400;
    margin-bottom: 34px;
    text-transform: uppercase;
  }

  .esprit_bloc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 27px;
    @media screen and (max-width: 768px) {
      max-width: 90%;
      flex-wrap: wrap;
    }
    .esprit_bloc_prop {
      width: 282px;
      height: 282px;
      margin-right: 15px;
      margin-left: 15px;
      background-position: center;
      background-size: cover;
      @media screen and (max-width: 768px) {
        max-width: 40%;
        max-height: 180px;
        margin-bottom: 10px;
      }
    }
    .esprit_bloc_1 {
      background-image: url("../../assets/img/20210911_124806.jpg");
    }
    .esprit_bloc_2 {
      background-image: url("../../assets/img/20220312_161047.jpg");
    }
    .esprit_bloc_3 {
      background-image: url("../../assets/img/20220312_161912.jpg");
    }
    .esprit_bloc_4 {
      background-image: url("../../assets/img/ATELIER.jpg");
    }
  }
  p {
    font-weight: 500;
    color: white;
    font-size: 18px;
    line-height: 22.5px;
    margin-bottom: 34px;
    text-align: center;
    text-transform: uppercase;
    line-height: 28px;
    @media screen and (max-width: 768px) {
      max-width: 90%;
    }
  }
}
