.deco {
  background-color: #a7d1e7;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  h2 {
    font-size: 36px;
    margin-top: 34px;
    font-weight: 400;
    margin-bottom: 34px;
    text-transform: uppercase;
  }

  p {
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    max-width: 1217px;
    margin-bottom: 30px;
    @media screen and (max-width: 768px) {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .deco_bloc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 27px;
    @media screen and (max-width: 768px) {
      max-width: 90%;
      flex-wrap: wrap;
    }
    .deco_bloc_prop {
      width: 282px;
      height: 282px;
      margin-right: 15px;
      margin-left: 15px;
      background-position: center;
      background-size: cover;
      @media screen and (max-width: 768px) {
        max-width: 40%;
        max-height: 180px;
        margin-bottom: 10px;
      }
    }
    .deco_bloc_1 {
      background-image: url("../../assets/img/deco_1.jpg");
    }
    .deco_bloc_2 {
      background-image: url("../../assets/img/deco_2.jpg");
    }
    .deco_bloc_3 {
      background-image: url("../../assets/img/deco_3.jpg");
    }
    .deco_bloc_4 {
      background-image: url("../../assets/img/deco_4.jpg");
    }
  }

}
