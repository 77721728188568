.presentation {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #c9a07b;
  height: 481px;
  @media screen and (max-width: 768px) {
    height: auto;
    flex-direction: column;
  }

  .portrait {
    width: 372px;
    height: 399px;
    background-image: url("../../assets/img/Famille.png");
    background-position: center;
    background-size: cover;
    margin-left: 0px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin-right: 40px;
    @media screen and (max-width: 768px) {
      margin-top: 20px;
      margin-bottom: 30px;
      margin-left: auto;
      margin-right: auto;
      width: 90%;
    }
  }
  p {
    max-width: 840px;
    font-size: 23px;
    color: white;
    line-height: 30px;
    text-align: center;
    margin-left: 0px;
    @media screen and (max-width: 768px) {
      margin-right: 20px;
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }
}
