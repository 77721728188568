.contact {
  background-color: #c9a07b;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;

  h2 {
    font-size: 36px;
    margin-top: 34px;
    font-weight: 400;
    margin-bottom: 34px;
    text-transform: uppercase;
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }

  p {
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
    max-width: 1217px;
    margin-bottom: 30px;
    font-weight: normal;
    span {
      font-weight: 900;
    }
  }

  .contact_bloc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      max-width: 90%;
    }
    .contact_vertical_line {
      border: 1px solid #fff;
      height: 700px;
      align-items: center;
      margin-left: 30px;
      margin-right: 30px;
      @media screen and (max-width: 768px) {
        height: 0px;
        width: 90%;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .contact_bloc_left {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-left: 50px;
      font-size: 20px;
      line-height: 30px;
      gap: 10px;
      @media screen and (max-width: 768px) {
        font-size: 16px;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
      }
      .euro {
        width: 40px;
      }

      a {
        text-decoration: none;
        color: #c32aa3;
      }
      span {
        margin-left: auto;
        margin-right: auto;
        font-weight: 900;
      }
      iframe {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .contact_bloc_right {
      display: flex;
      flex-direction: column;
      width: 50%;
      flex-grow: 1;
      font-size: 20px;
      line-height: 30px;
      align-items: center;
      @media screen and (max-width: 768px) {
        font-size: 16px;
        text-align: center;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      }

      a {
        text-decoration: none;
        color: #fff;
        font-weight: 900;
      }
      .horizontal_line {
        border: 1 px solid #fff;
        width: 300px;
      }
      hr {
        border: 1px solid white;
        width: 96%;
        margin-top: 20px;
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
      span {
        @media screen and (max-width: 768px) {
          font-size: 25px;
          font-weight: 900;
          margin-bottom: 20px;
        }
      }
      iframe {
        @media screen and (max-width: 768px) {
          height: 300px;
          width: 100%;
        }
      }
    }
  }
}
