.navbar {
  width: 100vw;
  height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

a {
  color: black;
}

a:hover {
  color: white;
}

.logo {
  font-family: "Allura";
  color: black;
  font-size: 36px;
  margin-top: 16px;
  margin-bottom: 13px;
  @media screen and (max-width: 400px) {
    margin-bottom: 0;
  }
  span {
    color: #c9a07b;
    font-family: "Allura";
  }
  @media screen and (max-width: 768px) {
    min-height: 40px;
  }
}

.menu {
  display: block;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

#mobile_menu {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    left: -100vw;
  }
}
#menu_label {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
    margin-bottom: 10px;
    font-size: 25px;
  }
}

#mobile_menu:not(:checked) ~ .menu {
  @media screen and (max_width: 768px) {
    display: none;
  }
}

#mobile_menu:checked ~ .menu {
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 100px;
    left: auto;
    right: auto;
    background-color: #c9a07b;
    margin-top: 20px;
    z-index: 6;
    width: 85%;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }
}

ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-top: 20px;
    padding: 0;
  }

  li {
    text-decoration: none;
    list-style: none;
    font-family: "Quicksand";
    width: 200px;
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    padding-top: 3px;
    padding-bottom: 3px;
    transition: ease 0.3s;
    &:hover {
      background-color: #c9a07b;
      color: white;
      cursor: pointer;
    }
    @media screen and (max-width: 768px) {
      margin-left: auto;
      margin-right: auto;
      font-size: 20px;
      margin-bottom: 30px;
      text-transform: uppercase;
      font-weight: 900;
    }
  }
}

.menu--left {
  border-radius: 5px 0 0 5px;
}
.menu--right {
  border-radius: 0 5px 5px 0;
}
