.slowlife {
  width: 100vw;
  height: 384px;
  background-color: #a7d1e7;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 768px) {
    height: auto;
    padding-bottom: 30px;
  }
  h2 {
    font-weight: 500;
    font-size: 36px;
    letter-spacing: 3px;
    margin-top: 42px;
    @media screen and (max-width: 768px) {
      font-weight: 400;
      font-size: 25px;
    }
  }
  img {
    width: 69px;
    margin-top: 8px;
  }
  h4 {
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    letter-spacing: 2px;
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }
  .slowlife--cards {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    .card {
      display: flex;
      flex-direction: column;
      font-size: 15px;
      max-width: 135px;
      text-align: center;
      padding-left: 3px;
      padding-right: 3px;
      margin-top: 30px;
      @media screen and (max-width: 768px) {
        min-width: 40vw;
        font-size: 18px;
      }
      .icon {
        font-size: 60px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
      }
      h5 {
        font-weight: 500;
      }
    }
  }
}
