.hebergement {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 36px;
    margin-top: 34px;
    color: #c9a07b;
    font-weight: 400;
    margin-bottom: 34px;
    text-transform: uppercase;
  }

  .hebergement_bloc {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 27px;
    @media screen and (max-width: 768px) {
      max-width: 90%;
      flex-wrap: wrap;
    }
    .hebergement_bloc_prop {
      width: 282px;
      height: 282px;
      margin-right: 15px;
      margin-left: 15px;
      background-position: center;
      background-size: cover;
      @media screen and (max-width: 768px) {
        max-width: 40%;
        max-height: 180px;
        margin-bottom: 10px;
      }
    }
    .hebergement_bloc_1 {
      background-image: url("../../assets/img/TERRASSE.jpg");
    }
    .hebergement_bloc_2 {
      background-image: url("../../assets/img/salle\ de\ bain.jpg");
    }
    .hebergement_bloc_3 {
      background-image: url("../../assets/img/vue\ du\ salon\ gite.jpg");
    }
    .hebergement_bloc_4 {
      background-image: url("../../assets/img/chambre.jpg");
    }
  }
  p {
    font-weight: 500;
    color: #c9a07b;
    font-size: 18px;
    line-height: 22.5px;
    margin-bottom: 34px;
    text-align: center;
    max-width: 1217px;
    text-transform: uppercase;
    line-height: 28px;
    @media screen and (max-width: 768px) {
      max-width: 90%;
    }
  }

  .hebergement_bloc_icon {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 768px) {
      max-width: 90%;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 400px) {
      width: 100%;
    }
    #bloc_4 {
      @media screen and (max-width: 768px) {
        margin-top: -45px;
      }
    }
    .hebergement_bloc_icon_column {
      display: flex;
      flex-direction: column;
      width: 282px;
      margin-left: 18px;
      margin-right: 18px;
      @media screen and (max-width: 768px) {
        max-width: 40%;
        margin-bottom: 10px;
      }
      @media screen and (max-width: 400px) {
        width: 40%;
        margin-left: 15px;
        margin-right: 15px;
      }
      .hebergement_bloc_icon_column_bloc {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        p {
          font-size: 13px;
          line-height: normal;
          margin-bottom: 20px;
        }
        .hebergement_p {
          width: 80%;
        }
        .hebergement_icon {
          width: 20%;
          @media screen and (max-width: 768px) {
            width: 100%;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
