.slider {
  background-image: url("../../assets/img/accueil.jpg");
  width: 100vw;
  height: 85vh;
  background-position: center;
  background-size: cover;
  z-index: 1;
  @media screen and (max-width: 768px) {
    height: 40vh;
  }
}
