* {
  margin: 0;
  font-family: "Quicksand";
  overflow-x: hidden;
}
.space_mobile {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.mobile_line {
  display: none;
  @media screen and (max-width: 768px) {
    display: block;
  }
}

.space_computer {
  @media screen and (max-width: 768px) {
    display: none;
  }
}
